<template>
  <Table
    v-if="showTable"
    custom-class="order-items-cart-table"
    :columns="tableColumns"
    :data="tableData"
    :border="true"
    rounded
    :cell-class="handleCellClass"
    :hover="false"
  >
    <template #cell-name="{ rowData: { name, sku, isRecommended, product, maxStockLevel, available } }">
      <ProductCell
        :name="name"
        :sku="sku"
        :is-available="available"
        :is-recommended="isRecommended"
        :uom="getOrderBOM(product?.bom)?.uom"
        :max-stock-level="maxStockLevel"
      />
    </template>

    <template
      #cell-lastOrderPrice="{
        rowIndex,
        rowData: { lastOrderPrice, lastOrderIntegralItems, lastOrderAssociatedItems, lastOrderDate },
      }"
    >
      <span ref="htmlElementRef" :class="{ 'grayed-out-text': !lastOrderPrice }">
        <el-tooltip
          effect="dark"
          placement="top"
          :disabled="!lastOrderPrice"
          :content="`${$t('orderProcessCart.OrderPriceInformationPopover.lastOrderDateTooltip')} (${lastOrderDate})`"
        >
          <span>{{ lastOrderPrice || MISSING_DATA_TEXT }}</span>
        </el-tooltip>

        <span v-if="lastOrderIntegralItems.length > 2">
          <el-tooltip
            effect="dark"
            placement="top"
            :content="$t('orderProcessCart.OrderPriceInformationPopover.tooltipContent')"
          >
            <el-popover
              :visible="showIngredientsPopupIndex === rowIndex"
              :placement="isLTR ? 'right' : 'left'"
              trigger="click"
              width="242"
              popper-class="popper-overrides"
            >
              <OrderPriceInformationPopover
                slot="default"
                :last-order-integral-items="lastOrderIntegralItems"
                :last-order-associated-items="lastOrderAssociatedItems"
                :title="$t('orderProcessCart.OrderPriceInformationPopover.popupTitle')"
                :title-date="lastOrderDate"
                @on-close="resetPopoverFocus"
              />
              <template #reference>
                <span @click="showIngredientsPopupIndex = rowIndex">
                  <LayersIcon class="layer-icon" />
                </span>
              </template>
            </el-popover>
          </el-tooltip>
        </span>
      </span>
    </template>

    <template #cell-quantity="{ rowData }">
      <OrderQuantityControls
        v-if="canInteractWithOrder"
        :row-data="rowData"
        :is-available="rowData.available"
        :hide-icons="true"
        :show-input-border="true"
        @on-quantity-change="quantityChanged"
        @on-quantity-save="quantitySave"
        @on-blur="onBlur"
      />
      <div v-else>
        <span v-if="!rowData.quantity" class="grayed-out-text">{{ MISSING_DATA_TEXT }}</span>
        <span v-else :class="{ 'alert-quantity': rowData.alertQuantity }">
          {{ rowData.quantity }}
        </span>
      </div>
    </template>

    <template #cell-maxStockLevel="{ rowData: { maxStockLevel } }">
      <p v-if="maxStockLevel">{{ maxStockLevel }}</p>
      <p v-else class="grayed-out-text">{{ MISSING_DATA_TEXT }}</p>
    </template>

    <template #cell-total="{ rowData: { total, product } }">
      <span :class="{ 'grayed-out-text': !total }">
        {{ total && product?.bom && isOrderAndBillingUnitInTheSameBOM(product.bom) ? total : MISSING_DATA_TEXT }}
      </span>
    </template>

    <template #cell-actions="{ rowData }">
      <el-tooltip
        v-if="canInteractWithOrder"
        effect="dark"
        :content="$t('orderProcessCart.removeTooltip')"
        placement="top"
      >
        <Button
          type="icon"
          :class="rowData.available ? 'actions-btn' : 'enabled-action-btn'"
          @click="() => removeItemFromCart(rowData)"
        >
          <TrashCanIcon :size="20" />
        </Button>
      </el-tooltip>
    </template>
  </Table>
</template>
<script>
import { Table, Button } from '@/modules/core';
import { ref, computed, getCurrentInstance } from 'vue';
import { TrashCanIcon, LayersIcon } from '@/assets/icons';
import { orderProcessCartItems } from './tableColumns';
import OrderPriceInformationPopover from './OrderPriceInformationPopover';
import { SOURCES } from './constants';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import OrderQuantityControls from './OrderQuantityControls.vue';
import ProductCell from './cellRenderers/ProductCell';
import { getOrderBOM, bomIsOrderAndBillingUnit } from '@/modules/purchase-management/purchaseManagement';

export default {
  components: {
    Table,
    Button,
    TrashCanIcon,
    LayersIcon,
    OrderPriceInformationPopover,
    OrderQuantityControls,
    ProductCell,
  },
  props: {
    productsList: { type: Array, default: () => [] },
    canInteractWithOrder: { type: Boolean, required: true },
  },
  emits: ['on-item-removal', 'on-quantity-save-update', 'on-quantity-change'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const isLTR = root.$i18n.t('direction') === 'ltr';

    const showTable = ref(true);
    const showIngredientsPopupIndex = ref(null);
    const htmlElementRef = ref(null);

    const productsList = computed(() => props.productsList);

    const removeItemFromCart = (rowData) => emit('on-item-removal', rowData);

    const quantityChanged = ({ rowData, quantity }) => {
      emit('on-quantity-change', { ...rowData, quantityState: quantity });
    };

    const quantitySave = ({ rowData, quantity }) => {
      emit('on-quantity-save-update', { ...rowData, quantity, quantityState: quantity });
    };

    const resetPopoverFocus = () => htmlElementRef.value.click();

    const onBlur = (rowData, quantity) => {
      if (quantity === 0) {
        removeItemFromCart(rowData);
      }
    };

    const handleCellClass = (rowIndex) => {
      if (productsList.value[rowIndex]?.available === false) {
        return 'cart-disabled-col';
      }
    };

    const isOrderAndBillingUnitInTheSameBOM = (bom) => bomIsOrderAndBillingUnit(getOrderBOM(bom));

    return {
      SOURCES,
      MISSING_DATA_TEXT,
      isLTR,
      showTable,
      htmlElementRef,
      tableData: productsList,
      showIngredientsPopupIndex,
      tableColumns: orderProcessCartItems(),
      resetPopoverFocus,
      removeItemFromCart,
      quantityChanged,
      quantitySave,
      getOrderBOM,
      onBlur,
      handleCellClass,
      isOrderAndBillingUnitInTheSameBOM,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep {
  @keyframes example {
    from {
      border: 3px solid green;
    }
    to {
      border: 0px;
    }
  }
}

.order-items-cart-table {
  tr {
    .actions-btn-hide {
      button {
        margin-inline-start: 1rem;
      }
    }
    .actions-btn {
      display: none;
    }

    .enabled-action-btn,
    &:hover .actions-btn {
      display: inline;
      width: 32px;
      height: 32px;
      color: $checkbox-error;
    }

    .over-average-quantity-wrapper {
      float: left;

      .over-average-quantity {
        color: orange;
      }
    }

    &:hover .actions-btn {
      display: inline;
    }
  }
  .grayed-out-text {
    color: #c4c6cf !important;
  }

  .alert-quantity {
    color: #e47e03;
  }

  .layer-icon {
    color: #9295a5;
    margin-inline-start: 0.25rem;
    margin-block-end: 0.2rem;

    &:hover {
      cursor: pointer;
      color: #1f284d;
    }
  }
}
</style>
<style lang="scss">
.order-items-cart-table {
  tr {
    &:hover {
      .order-quantity-product-latest-order-data {
        display: block !important;
      }
    }
  }
}
.cart-disabled-col {
  * {
    color: #9295a5;
  }

  .enabled-action-btn,
  .enabled-action-btn * {
    color: inherit;
  }
}
</style>
